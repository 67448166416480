import React, { useState } from 'react'
import { TableColumn } from 'react-data-table-component'

import { useRequest } from 'ahooks'

import { listSubsecretarias } from 'sigap/services/subsecretaria'

import { GenericAdminListTab } from '../GenericAdminListTab/GenericAdminListTab'
import { AdminContainer } from '../styles'

const subsecColumns: TableColumn<ISubsecretaria>[] = [
  {
    name: 'ID',
    selector: (row) => row.id,
    sortable: true,
    grow: 10
  },
  {
    name: 'NOME',
    selector: (row) => row.nome,
    sortable: true,
    grow: 40,
    wrap: true
  }
]

export const SubsecretariaList: React.FC = () => {
  const [showSubsecModal, setShowSubsecModal] = useState<boolean>(false)
  const [subsecModalData, setSubsecModalData] = useState<ISubsecretaria>(null)

  const { data: subsec, loading: subsecLoading } = useRequest(listSubsecretarias)

  const handleShowModal = (subsec?: ISubsecretaria): void => {
    setShowSubsecModal(true)
    if (subsec != null) {
      setSubsecModalData(subsec)
    }
  }

  return (
    <>
      <AdminContainer>
        <GenericAdminListTab
          columns={subsecColumns}
          data={subsec}
          title="Subsecretarias"
          loading={subsecLoading}
          onRowClick={handleShowModal}
          onAddClick={() => handleShowModal()}
          dataType="subsecretaria"
        />
      </AdminContainer>
    </>
  )
}
