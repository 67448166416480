import React, { useContext, useState } from 'react'
import DataTable, { TableColumn } from 'react-data-table-component'
import { BsPlusLg } from 'react-icons/bs'

import { CircularProgress } from '@chakra-ui/react'

import { EndButtonRow } from 'sigap/utils/generic/EndButtonRow/styles'
import { GenericButton } from 'sigap/utils/generic/GenericButton/GenericButton'
import { NoDataComponent } from 'sigap/utils/generic/NoDataComponent/NoDataComponent'

import { GenericTableContainer } from './styles'

import { FaFileWord, FaFilePdf, FaLink, FaFileAlt, FaFilePowerpoint, FaEdit, FaTimes } from 'react-icons/fa';

import { FcDocument } from "react-icons/fc";

// import SeiIcon from 'sigap/images/logo_sei.png?raw';

import { de } from 'date-fns/locale'
import { padding } from 'polished'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import { deleteDocument, getDocumentosByContrato } from 'sigap/services/documentos'
import { AuthenticationContext } from 'sigap/context/AuthenticationContext'
import { useRequest } from 'ahooks'

interface IGenericListProps<T> {
  title: string
  columns: TableColumn<T>[]
  data: T[]
  tempData?: T[]
  isPending?: boolean
  isLoading: boolean
  onAddClick: () => void
  onRowClick?: (row: T) => void
  onRowDelete?: () => void
  noDataType?: string
  refreshDocumentos: () => Promise<IDocumentos[]>
}

export const GenericTable = <T,>({
  title,
  columns,
  data,
  tempData,
  isPending,
  isLoading,
  onAddClick,
  onRowClick,
  onRowDelete,
  noDataType = 'dados',
  refreshDocumentos
}: IGenericListProps<T>): JSX.Element => {

  const SwalAlert = withReactContent(Swal)
  const authContext = useContext(AuthenticationContext)
  
  if (data===undefined) {
    data = []
  }

  const handleOnRowClick = (row: T): void => {
    if (!isPending && onRowClick) {
      onRowClick(row)
    }
  }


  const handleRowDelete = (row: T): void => {
    SwalAlert.fire({
      title: 'Tem certeza que deseja excluir?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      // cancelButtonColor: '#3085d6',
      cancelButtonText: "Cancelar",
      confirmButtonText: 'Sim, excluir!'
    }).then(async (result) => {
      //implementar delete request
      if (result.isConfirmed) {

        try {
          const response = await deleteDocument(row, authContext?.currentUser?.id)

          if (response.status !== 200) {
            SwalAlert.fire({
              toast: true,
              position: 'bottom',
              icon: 'error',
              title: 'Algo deu errado.',
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          }else{
            // If successful, show success message
            SwalAlert.fire({
              toast: true,
              position: 'bottom',
              icon: 'success',
              title: 'Sucesso!',
              showConfirmButton: false,
              timer: 1000,
              timerProgressBar: true,
            });
          }
          SwalAlert.fire(
            'Excluído!',
            'O registro foi excluído.',
            'success'
          )
          refreshDocumentos()
        }
        catch (error) {
          SwalAlert.fire({
            toast: true,
            position: 'bottom',
            icon: 'error',
            title: 'Erro no servidor.',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    })
  }

  const renderIcon = (type) => {
    switch (type) {
      case 1: //word
        return <FaFileWord style={{ color: 'blue', width: '50px', height: '50px' }} />;
      case 2: //powerpoint
        return <FaFilePowerpoint style={{ color: 'red', width: '50px', height: '50px' }} />;
      case 3: //SEI
        return <FaLink style={{ color: 'green', width: '50px', height: '50px' }} />;
      case 5: //PDF
        return <FaFilePdf style={{ color: 'red', width: '50px', height: '50px' }} />;
      default: //other
        return <FaFileAlt style={{ color: 'gray', width: '50px', height: '50px' }} />;
    }
  };

  return (
    <GenericTableContainer>
      <div className="meta-header">{title}</div>
      <div className="meta-content">
        <div className="list-container">
          <div className="list-body">
            {data.length == 0 && 
              <NoDataComponent dataType="documentos" />
            }
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {data
                .sort((a, b) => a.nome.localeCompare(b.nome))
                .map((doc, index) => (
                  <div style={padding('10px')} key={index}>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '5px' }}>
                      <button
                        onClick={() => (handleOnRowClick(doc))}
                        style={{
                          background: 'none',
                          border: 'none',
                          cursor: 'pointer',
                          marginRight: '10px',
                        }}
                      >
                        <FaEdit style={{ color: '#707dd0' }} />
                      </button>
                      <button
                        onClick={() => handleRowDelete(doc.id_doc)} // Ensure the function is called
                        style={{
                          background: 'none',
                          border: 'none',
                          cursor: 'pointer',
                        }}
                      >
                        <FaTimes style={{ color: 'red' }} />
                      </button>
                    </div>
                    <div
                      key={index}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        margin: '10px',
                        padding: '2px',
                        cursor: 'pointer',
                      }}
                      onClick={() => window.open(doc.link, '_blank').focus()}
                    >
                      {renderIcon(doc.tipo_id)}
                      <p style={{ marginTop: '5px', textAlign: 'center', color: 'blue', width: '100px' }}>{doc.nome}</p>
                    </div>
                  </div>
                ))}
              </div>
          </div>
          <EndButtonRow style={{ padding: '16px' }}>
            <GenericButton
              className="add-button"
              colorScheme="green"
              onClick={onAddClick}
              isDisabled={isPending}
              rightIcon={<BsPlusLg />}
            >
              Adicionar
            </GenericButton>
          </EndButtonRow>
        </div>
      </div>
    </GenericTableContainer>
    // <GenericTableContainer>
    //   <div className="meta-header">{title}</div>
    //   <div className="meta-content">
    //     <div className="list-container">
    //       <div className="list-body">
    //         <DataTable
    //           columns={columns}
    //           data={tempData && tempData.length > 0 ? tempData : data}
    //           onRowClicked={handleOnRowClick}
    //           dense
    //           pointerOnHover={!isPending}
    //           highlightOnHover={!isPending}
    //           striped
    //           progressPending={isLoading}
    //           progressComponent={<CircularProgress isIndeterminate color="#001B37" />}
    //           noDataComponent={<NoDataComponent dataType={noDataType} />}
    //         />
    //       </div>
    //       <EndButtonRow style={{ padding: '16px' }}>
    //         <GenericButton
    //           className="add-button"
    //           colorScheme="green"
    //           onClick={onAddClick}
    //           isDisabled={isPending}
    //           rightIcon={<BsPlusLg />}
    //         >
    //           Adicionar
    //         </GenericButton>
    //       </EndButtonRow>
    //     </div>
    //   </div>
    // </GenericTableContainer>
  )
}
