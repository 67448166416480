import React from 'react'

import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'

import { ODSList } from './ODSList/ODSList'
import { SedeicsList } from './SedeicsList/SedeicsList'
import { AdminContainer } from './styles'
import { SubsecretariaList } from './SubsecretariaList/SubsecretariaList'
import { UserList } from './UserList/UserList'

export const Admin: React.FC = () => {
  return (
    <AdminContainer>
      <Tabs>
        <TabList>
          <Tab>Usuários</Tab>
          <Tab>Subsecretarias</Tab>
          <Tab>ODS</Tab>
          <Tab>Missões SEDEICS</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <UserList />
          </TabPanel>
          <TabPanel>
            <SubsecretariaList />
          </TabPanel>
          <TabPanel>
            <ODSList />
          </TabPanel>
          <TabPanel>
            <SedeicsList />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </AdminContainer>
  )
}
