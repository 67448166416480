import React, { useState, useEffect, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'

import {
  Button,
  CircularProgress,
  Input,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
  useOutsideClick,
  useToast
} from '@chakra-ui/react'
import { useRequest } from 'ahooks'

import {
  editTarefasOnProject,
  getCommentsOnTarefa,
  getSubtarefasOnTarefa,
  insertSubtarefa,
  insertTarefasOnContrato,
  insertTarefasOnDemanda,
  insertTarefasOnProject
} from 'sigap/services/tarefas'
import { listUsers } from 'sigap/services/users'
import { IContrato } from 'sigap/types/contracts'
import { IProject } from 'sigap/types/projects'
import { ISubtarefa, ITarefa } from 'sigap/types/tarefas'
import { EntityType } from 'sigap/utils/constants/enums'
import { TAREFA_CREATE_TOAST_MESSAGE, TAREFA_EDIT_TOAST_MESSAGE } from 'sigap/utils/constants/requestToastMessages'
import { ModalContainer } from 'sigap/utils/generic/ModalContainer/ModalContainer'
import { SingleDatePicker } from 'sigap/utils/generic/SingleDatePicker/SingleDatePicker'
import { addThreeDigits } from 'sigap/utils/methods/addThreeDigits'
import { returnNumberValueArrayFromMultiSelect } from 'sigap/utils/methods/returnNumberValueArrayFromMultiSelect'

import { CommentChat } from '../CommentChat/CommentChat'
import { GenericButton } from '../GenericButton/GenericButton'
import { GenericSelect } from '../GenericSelect/GenericSelect'
import { InputStack } from '../InputStack/InputStack'

interface IFormData {
  nome: string
  responsavel: Option<number>[]
  tipo: Option<EntityType>
  pai: Option<number>
  descricao: string
  status
}

interface IProps {
  showNovaTarefaModal: boolean
  setShowNovaTarefaModal: (k: boolean) => void
  projetoId?: number
  setTarefaModalData: React.Dispatch<React.SetStateAction<ITarefa>>
  isFinalScreen?: boolean
  tarefaModalData?: ITarefa
  refreshTarefas: () => Promise<ITarefa[]>
  projects: IProject[]
  contratos: IContrato[]
  demandas: IDemanda[]
}

export const GenericNovaTarefaModal: React.FC<IProps> = ({
  showNovaTarefaModal,
  setShowNovaTarefaModal,
  tarefaModalData,
  setTarefaModalData,
  refreshTarefas,
  contratos,
  demandas,
  projects
}) => {
  const [selectedDate, setSelectedDate] = useState<Date>(new Date())
  const [projectMapById, setProjectMapById] = useState<Map<number, IProject>>()
  const [contratoMapById, setContratoMapById] = useState<Map<number, IContrato>>()
  const [demandaMapById, setDemandaMapById] = useState<Map<number, IDemanda>>()
  const [inputCounter, setInputCounter] = useState<number>(1)

  // const ref = React.useRef()
  // useOutsideClick({
  //   ref,
  //   handler: () => setShowNovaTarefaModal(false)
  // })

  const { handleSubmit, control, setValue, watch } = useForm<IFormData>()

  const currentFormData = watch()

  const toast = useToast()

  const { data: users } = useRequest(listUsers)

  const {
    data: subtarefas,
    refresh: refreshSubtarefas,
    loading: subtarefasLoading
  } = useRequest(getSubtarefasOnTarefa, {
    defaultParams: [tarefaModalData?.id_tarefa],
    ready: !!tarefaModalData
  })

  const { data: comments } = useRequest(getCommentsOnTarefa, {
    defaultParams: [tarefaModalData?.id_tarefa],
    ready: !!tarefaModalData
  })

  const isFormComplete = useMemo(
    () => currentFormData.nome?.length > 0 && currentFormData.responsavel != null,
    [currentFormData]
  )

  const handleCleanForm = (): void => {
    setSelectedDate(new Date())
    setValue('nome', '')
    setValue('responsavel', undefined)
  }

  const handleCloseNovaTarefaModal = (): void => {
    handleCleanForm()
    setShowNovaTarefaModal(false)
    setTarefaModalData(undefined)
  }
  const handleDateChange = (date: Date): void => {
    setSelectedDate(date)
  }

  const generatePaiOptions = (): Option<number>[] => {
    if (currentFormData.tipo?.value === EntityType.PROJETO) {
      return projects?.map((project) => ({
        label: `${project.id} - ${project.nome}`,
        value: project.id
      }))
    }
    if (currentFormData.tipo?.value === EntityType.CONTRATO) {
      return contratos?.map((contrato) => ({
        label: `${contrato.id} - ${contrato.nome}`,
        value: contrato.id
      }))
    }
    if (currentFormData.tipo?.value === EntityType.DEMANDA) {
      return demandas?.map((demanda) => ({
        label: `${demanda.id} - ${demanda.nome}`,
        value: demanda.id
      }))
    }
  }

  const generateStatusOptions = (): Option<number>[] => {
    return [
      { label: 'A Fazer', value: 1 },
      { label: 'Em Progresso', value: 2 },
      { label: 'Concluído', value: 3 }
    ]
  }

  const normalizeTarefaObjectForCreate = (formObject: IFormData): ITarefa => {
    if (selectedDate) {
      const normalizedTarefaObject: ITarefa = {
        data_acao: selectedDate.getTime(),
        titulo: formObject.nome,
        responsavel: returnNumberValueArrayFromMultiSelect(formObject.responsavel),
        id_pai: formObject?.pai?.value,
        descricao: formObject?.descricao,
        tipo: formObject?.tipo?.value
      }

      return normalizedTarefaObject
    }
  }

  const handleCreateTarefa = async (formObject: IFormData): Promise<void> => {
    const normalizedTarefa = normalizeTarefaObjectForCreate(formObject)
    if (normalizedTarefa.tipo === EntityType.PROJETO) {
      await insertTarefasOnProject({ tarefas: [normalizedTarefa] }, normalizedTarefa.id_pai)
      refreshTarefas()
      handleCloseNovaTarefaModal()
    }
    if (normalizedTarefa.tipo === EntityType.CONTRATO) {
      await insertTarefasOnContrato({ tarefas: [normalizedTarefa] }, normalizedTarefa.id_pai)
      refreshTarefas()
      handleCloseNovaTarefaModal()
    }
    if (normalizedTarefa.tipo === EntityType.DEMANDA) {
      await insertTarefasOnDemanda({ tarefas: [normalizedTarefa] }, normalizedTarefa.id_pai)
      refreshTarefas()
      handleCloseNovaTarefaModal()
    }
  }

  const handleClickSubmitCreate = (formObject: IFormData): void => {
    toast.promise<void, Error>(handleCreateTarefa(formObject), TAREFA_CREATE_TOAST_MESSAGE)
  }

  const normalizeTarefaObjectForEdit = (formObject: IFormData): ITarefa => {
    if (selectedDate) {
      const normalizedTarefaObject: ITarefa = {
        data_acao: selectedDate.getTime(),
        titulo: formObject.nome,
        responsavel: returnNumberValueArrayFromMultiSelect(formObject.responsavel),
        id_projeto: tarefaModalData?.id_projeto,
        id_tarefa: tarefaModalData?.id_tarefa,
        descricao: formObject?.descricao
      }

      return normalizedTarefaObject
    }
  }

  const handleEditTarefa = async (formObject: IFormData): Promise<void> => {
    const normalizedTarefa = normalizeTarefaObjectForEdit(formObject)
    await editTarefasOnProject({ tarefas: [normalizedTarefa] }, normalizedTarefa.id_projeto)
    refreshTarefas()
    handleCloseNovaTarefaModal()
  }

  const handleClickSubmitEdit = (formObject: IFormData): void => {
    toast.promise<void, Error>(handleEditTarefa(formObject), TAREFA_EDIT_TOAST_MESSAGE)
  }

  const handleAddSubtarefa = async (subtarefa: ISubtarefa): Promise<void> => {
    try {
      await insertSubtarefa(subtarefa, tarefaModalData.id_tarefa)
      setTarefaModalData((old) => ({ ...old, subtarefas: [...(old.subtarefas ?? []), subtarefa] }))
    } catch (e) {}
  }

  const returnOptionArrayFromUserArray = (users: IUser[]): Option<number>[] => {
    const optionsArray: Option<number>[] = []

    users?.map((user) => optionsArray.push({ label: user.nome, value: user.id }))

    return optionsArray
  }

  const generateTipoOptions = (): Option<string>[] => {
    return [
      { label: EntityType.PROJETO, value: EntityType.PROJETO },
      { label: EntityType.CONTRATO, value: EntityType.CONTRATO },
      { label: EntityType.DEMANDA, value: EntityType.DEMANDA }
    ]
  }

  useEffect(() => {
    if (projects) {
      const projetosMap = projects.reduce((acc, curr) => {
        acc.set(curr.id, curr)

        return acc
      }, new Map<number, IProject>())
      setProjectMapById(projetosMap)
    }
  }, [projects])

  useEffect(() => {
    if (contratos) {
      const contratosMap = contratos.reduce((acc, curr) => {
        acc.set(curr.id, curr)

        return acc
      }, new Map<number, IContrato>())
      setContratoMapById(contratosMap)
    }
  }, [contratos])

  useEffect(() => {
    if (demandas) {
      const demandasMap = demandas.reduce((acc, curr) => {
        acc.set(curr.id, curr)

        return acc
      }, new Map<number, IDemanda>())
      setDemandaMapById(demandasMap)
    }
  }, [demandas])

  console.log(tarefaModalData)

  useEffect(() => {
    if (tarefaModalData) {
      setValue('nome', tarefaModalData.titulo)
      setValue('responsavel', returnOptionArrayFromUserArray(tarefaModalData.responsavel as IUser[]))
      setSelectedDate(new Date(addThreeDigits(tarefaModalData.data_acao)))
      setValue('tipo', { label: tarefaModalData.tipo, value: tarefaModalData.tipo })
      if (tarefaModalData.tipo === EntityType.PROJETO) {
        setValue('pai', {
          label: `${tarefaModalData.id_projeto} - ${projectMapById?.get(tarefaModalData.id_projeto)?.nome}`,
          value: tarefaModalData.id_projeto
        })
      }
      if (tarefaModalData.tipo === EntityType.CONTRATO) {
        setValue('pai', {
          label: `${tarefaModalData.id_contrato} - ${contratoMapById?.get(tarefaModalData.id_contrato)?.nome}`,
          value: tarefaModalData.id_contrato
        })
      }
      if (tarefaModalData.tipo === EntityType.DEMANDA) {
        setValue('pai', {
          label: `${tarefaModalData.id_demanda} - ${demandaMapById?.get(tarefaModalData.id_demanda)?.nome}`,
          value: tarefaModalData.id_demanda
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tarefaModalData])

  return (
    <ModalContainer isOpen={showNovaTarefaModal} onClose={console.log}>
      <ModalOverlay />
      <ModalContent minWidth={1000} className="nova-meta-modal-content" width={'100%'}>
        <ModalHeader
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          className="nova-meta-modal-header"
          color={'gray.900'}
          fontFamily={'Poppins'}
          fontSize={32}
          fontStyle={'normal'}
          fontWeight={700}
          lineHeight={'36px'}
        >
          {tarefaModalData ? <>{tarefaModalData.titulo}</> : <>Nova Tarefa</>}
        </ModalHeader>
        <ModalBody padding={'0px 48px'} className="nova-meta-modal-body">
          <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '32px', marginBottom: '16px' }}>
            <div
              className="generic-tarefa-modal-column"
              style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}
            >
              <div className="label-input-bundle">
                <label>Nome da Tarefa</label>
                <Controller
                  name="nome"
                  control={control}
                  render={({ field }) => <Input variant="flushed" {...field} />}
                />
              </div>
              <div className="label-input-bundle">
                <label>Responsável pela Tarefa</label>
                <Controller
                  name="responsavel"
                  control={control}
                  render={({ field }) => (
                    <GenericSelect
                      isMulti
                      options={users?.map((user) => ({ label: user.nome, value: user.id }))}
                      {...field}
                    />
                  )}
                />
              </div>
              <div className="label-input-bundle">
                <label>Prazo de Realização</label>
                <SingleDatePicker
                  onDateChange={handleDateChange}
                  selectedDate={selectedDate}
                  setSelectedDate={setSelectedDate}
                />
              </div>
              <div className="label-input-bundle">
                <label>Descrição</label>
                <Controller name="descricao" control={control} render={({ field }) => <Textarea {...field} />} />
              </div>
              <div className="label-input-bundle">
                <label>Tipo</label>
                <Controller
                  name="tipo"
                  control={control}
                  render={({ field }) => (
                    <GenericSelect {...field} options={generateTipoOptions()} isDisabled={!!tarefaModalData} />
                  )}
                />
              </div>
              <div className="label-input-bundle">
                <label>Projeto, Demanda ou Contrato</label>
                <Controller
                  name="pai"
                  control={control}
                  render={({ field }) => (
                    <GenericSelect options={generatePaiOptions()} {...field} isDisabled={!!tarefaModalData} />
                  )}
                />
              </div>
              <div className="label-input-bundle">
                <label>Status</label>
                <Controller
                  name="status"
                  control={control}
                  render={({ field }) => (
                    <GenericSelect options={generateStatusOptions()} {...field} isDisabled={!!tarefaModalData} />
                  )}
                />
              </div>
            </div>
            <div
              className="generic-tarefa-modal-column"
              style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}
            >
              <label>Subtarefas</label>
              <div className="label-input-bundle">
                {subtarefasLoading ? (
                  <CircularProgress isIndeterminate color="#001B37" />
                ) : (
                  <InputStack
                    subtarefas={subtarefas}
                    tarefaId={tarefaModalData?.id_tarefa}
                    refreshSubtarefas={refreshSubtarefas}
                  />
                )}
              </div>
            </div>
          </div>

          {tarefaModalData?.id_tarefa && (
            <CommentChat comments={comments} type="TAREFA" entityId={tarefaModalData?.id_tarefa} />
          )}
        </ModalBody>
        <ModalFooter display={'flex'} gap={'80px'}>
          {tarefaModalData ? (
            <GenericButton onClick={handleSubmit(handleClickSubmitEdit)} isDisabled={!isFormComplete}>
              Salvar Edição
            </GenericButton>
          ) : (
            <GenericButton onClick={handleSubmit(handleClickSubmitCreate)} isDisabled={!isFormComplete}>
              Adicionar e Salvar
            </GenericButton>
          )}

          <GenericButton variant={'outline'} onClick={handleCloseNovaTarefaModal} color={'#242731'}>
            Cancelar
          </GenericButton>
        </ModalFooter>
      </ModalContent>
    </ModalContainer>
  )
}
function setIsModalOpen(arg0: boolean): void {
  throw new Error('Function not implemented.')
}
