import { CONTRATOS, DEMANDAS, DOCUMENTOS, PROJETOS, USER, api, cancelToken } from 'sigap/utils/constants'

export interface IDocumentosDto {
  documentos: IDocumentos[]
}

export async function listDocumentos(): Promise<IDocumentos[]> {
  const { data: response } = await api.get(`/${DOCUMENTOS}`)

  return response
}

export async function getDocumento(tarefaId: number): Promise<IDocumentos> {
  const { data: response } = await api.get(`/${DOCUMENTOS}/${tarefaId}`)

  return response
}

export async function editDocumento(documento: IDocumentos, documentoId: number): Promise<IDocumentos> {
  const { data: response } = await api.patch(`/${DOCUMENTOS}/${documentoId}`, documento)

  return response
}

export async function insertDocumentosOnProject(documentos: IDocumentosDto, projectId: number): Promise<IDocumentos> {
  const { data: response } = await api.post(`/${DOCUMENTOS}/${PROJETOS}/${projectId}`, documentos)

  return response
}

export async function insertDocumentosOnDemanda(documentos: IDocumentosDto, demandaId: number): Promise<IDocumentos> {
  const { data: response } = await api.post(`/${DOCUMENTOS}/${DEMANDAS}/${demandaId}`, documentos)

  return response
}
export async function insertDocumentosOnContrato(documentos: IDocumentosDto, contratoId: number): Promise<IDocumentos> {
  const { data: response } = await api.post(`/${DOCUMENTOS}/${CONTRATOS}/${contratoId}`, documentos)

  return response
}

export async function getDocumentosByProject(projectId: number): Promise<IDocumentos[]> {
  const { data: response } = await api.get(`/${DOCUMENTOS}/${PROJETOS}/${projectId}`)

  return response
}

export async function getDocumentosByUser(userId: number): Promise<IDocumentos[]> {
  const { data: response } = await api.get(`/${DOCUMENTOS}/${USER}/${userId}`, { cancelToken: cancelToken.token })

  return response
}

export async function getDocumentosByDemanda(demandaId: number): Promise<IDocumentos[]> {
  const { data: response } = await api.get(`/${DOCUMENTOS}/${DEMANDAS}/${demandaId}`)

  return response
}

export async function getDocumentosByContrato(contratoId: number): Promise<IDocumentos[]> {
  const { data: response } = await api.get(`/${DOCUMENTOS}/${CONTRATOS}/${contratoId}`)

  return response
}


export async function deleteDocument(documentId: number, id: any): Promise<any> {
  const response = await api.delete(`/${DOCUMENTOS}/${documentId}`)

  return response
}
// export async function editDocumento(tarefa: Partial<IDocumentos>, tarefaId: number): Promise<IDocumentos> {
//   const { data: response } = await api.patch(`/${DOCUMENTOS}/${tarefaId}`, tarefa)

//   return response
// }
