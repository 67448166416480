import React, { useState, useEffect, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'

import {
  Input,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
  useToast
} from '@chakra-ui/react'

import {
  editDocumento,
  insertDocumentosOnContrato,
  insertDocumentosOnDemanda,
  insertDocumentosOnProject
} from 'sigap/services/documentos'
import {
  DOCUMENTO_CREATE_TOAST_MESSAGE,
  DOCUMENTO_EDIT_TOAST_MESSAGE,
  TAREFA_CREATE_TOAST_MESSAGE,
  TAREFA_EDIT_TOAST_MESSAGE
} from 'sigap/utils/constants/requestToastMessages'
import { GenericButton } from 'sigap/utils/generic/GenericButton/GenericButton'
import { GenericSelect } from 'sigap/utils/generic/GenericSelect/GenericSelect'
import { ModalContainer } from 'sigap/utils/generic/ModalContainer/ModalContainer'

const tipoSelectOpions: Option<number>[] = [
  { label: 'Word', value: 1 },
  { label: 'PowerPoint', value: 2 },
  { label: 'SEI', value: 3 },
  { label: 'Outros', value: 4 },
  { label: 'PDF', value: 5 }
]

interface IFormData {
  nome: string
  tipo: Option<number>
  link: string
}

interface IProps {
  showNovoDocumentoModal: boolean
  setShowNovoDocumentoModal: (k: boolean) => void
  entityId: number
  setDocumentoModalData: React.Dispatch<React.SetStateAction<IDocumentos>>
  documentoModalData?: IDocumentos
  refreshDocumentos: () => Promise<IDocumentos[]>
  setTempDocumentos?: React.Dispatch<React.SetStateAction<IDocumentos[]>>
  tempDocumento?: IDocumentos[]
  isNewEntityScreen?: boolean
  type: 'PROJETO' | 'CONTRATO' | 'DEMANDA'
}

export const ModalNovoDocumento: React.FC<IProps> = ({
  showNovoDocumentoModal,
  entityId,
  tempDocumento,
  isNewEntityScreen,
  refreshDocumentos,
  setDocumentoModalData,
  setShowNovoDocumentoModal,
  documentoModalData,
  setTempDocumentos,
  type
}) => {
  const { handleSubmit, control, setValue, watch } = useForm<IFormData>()

  const [count, setCount] = useState<number>(1)

  const currentFormData = watch()

  const toast = useToast()

  const isFormComplete = useMemo(
    () => currentFormData.link?.length > 0 && currentFormData.tipo != null,
    [currentFormData]
  )

  const handleCleanForm = (): void => {
    setValue('nome', '')
    setValue('link', '')
    setValue('tipo', undefined)
  }

  const handleCloseNovoDucumentoModal = (): void => {
    handleCleanForm()
    setShowNovoDocumentoModal(false)
    setDocumentoModalData(undefined)
  }

  const normalizeTempDocumentoObject = (formObject: IFormData): IDocumentos => {
    const normalizedDocumentoObject: IDocumentos = {
      nome: formObject.nome,
      link: formObject.link,
      tipo_id: formObject.tipo.value,
      id: count,
      id_doc: documentoModalData?.id
    }
    setCount(count + 1)

    return normalizedDocumentoObject
  }

  const normalizeDocumentoObject = (formObject: IFormData): IDocumentos => {
    const normalizedDocumentoObject: IDocumentos = {
      nome: formObject.nome,
      link: formObject.link,
      tipo_id: formObject.tipo.value,
      id_doc: documentoModalData?.id_doc
    }

    return normalizedDocumentoObject
  }
  const handleCreateDocumento = async (formObject: IFormData): Promise<void> => {
    const normalizedDocumento = normalizeDocumentoObject(formObject)
    try {
      if (type === 'PROJETO') {
        await insertDocumentosOnProject({ documentos: [normalizedDocumento] }, entityId)
        refreshDocumentos()
        handleCloseNovoDucumentoModal()
      }
      if (type === 'CONTRATO') {
        await insertDocumentosOnContrato({ documentos: [normalizedDocumento] }, entityId)
        refreshDocumentos()
        handleCloseNovoDucumentoModal()
      }
      if (type === 'DEMANDA') {
        await insertDocumentosOnDemanda({ documentos: [normalizedDocumento] }, entityId)
        refreshDocumentos()
        handleCloseNovoDucumentoModal()
      }
    } catch (e) {
      console.log(e)
    }
  }

  const handleNovoTempDocumento = (formObject: IFormData): void => {
    const normalizedTempDocumento = normalizeTempDocumentoObject(formObject)
    setTempDocumentos((current) => [...current, normalizedTempDocumento])
    handleCleanForm()
    setShowNovoDocumentoModal(false)
  }

  const handleEditTempDocumento = (formObject: IFormData): void => {
    if (documentoModalData) {
      const normalizedTempDocumento = normalizeDocumentoObject(formObject)
      const newData = tempDocumento.map((documento) => {
        if (documento.id === documentoModalData.id) {
          return normalizedTempDocumento
        }

        return documento
      })
      setTempDocumentos(newData)
      handleCloseNovoDucumentoModal()
    }
  }

  const handleClickSubmitCreate = (formObject: IFormData): void => {
    toast.promise<void, Error>(handleCreateDocumento(formObject), DOCUMENTO_CREATE_TOAST_MESSAGE)
  }

  const handleEditDocumento = async (formObject: IFormData): Promise<void> => {
    try {
      const normalizedDocumento = normalizeDocumentoObject(formObject)

      await editDocumento(normalizedDocumento, normalizedDocumento.id_doc)
      refreshDocumentos()
      handleCloseNovoDucumentoModal()
    } catch (e) {
      console.log(e)
    }
  }

  const handleClickSubmitEdit = (formObject: IFormData): void => {
    toast.promise<void, Error>(handleEditDocumento(formObject), DOCUMENTO_EDIT_TOAST_MESSAGE)
  }

  useEffect(() => {
    if (documentoModalData) {
      setValue('nome', documentoModalData.nome)
      setValue('tipo', {
        value: documentoModalData.tipo_id,
        label: tipoSelectOpions[documentoModalData.tipo_id-1]?.label
      })
      setValue('link', documentoModalData.link)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentoModalData])

  return (
    <ModalContainer isOpen={showNovoDocumentoModal} onClose={console.log}>
      <ModalOverlay />
      <ModalContent minWidth={800} className="nova-meta-modal-content">
        <ModalHeader
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          className="nova-meta-modal-header"
          color={'gray.900'}
          fontFamily={'Poppins'}
          fontSize={32}
          fontStyle={'normal'}
          fontWeight={700}
          lineHeight={'36px'}
        >
          {documentoModalData ? 'Documento' : 'Novo Documento'}
        </ModalHeader>
        <ModalBody
          display={'flex'}
          flexDirection={'column'}
          padding={'0px 48px'}
          gap={'32px'}
          className="nova-meta-modal-body"
        >
          <div className="new-project-label-input-bundle">
            <label>Nome</label>
            <Controller name="nome" control={control} render={({ field }) => <Input {...field} />} />
          </div>
          <div className="label-input-bundle">
            <label>Tipo</label>
            <Controller
              name="tipo"
              control={control}
              render={({ field }) => <GenericSelect required options={tipoSelectOpions} {...field} />}
            />
          </div>
          <div className="new-project-label-input-bundle">
            <label>Link</label>
            <Controller name="link" control={control} render={({ field }) => <Textarea {...field} />} />
          </div>
        </ModalBody>
        <ModalFooter display={'flex'} gap={'80px'}>
          {!isNewEntityScreen ? (
            <>
              {documentoModalData ? (
                <GenericButton onClick={handleSubmit(handleClickSubmitEdit)} isDisabled={!isFormComplete}>
                  Salvar Edição
                </GenericButton>
              ) : (
                <GenericButton onClick={handleSubmit(handleClickSubmitCreate)} isDisabled={!isFormComplete}>
                  Adicionar e Salvar
                </GenericButton>
              )}
            </>
          ) : documentoModalData ? (
            <GenericButton onClick={handleSubmit(handleEditTempDocumento)} isDisabled={!isFormComplete}>
              Salvar Edição
            </GenericButton>
          ) : (
            <GenericButton onClick={handleSubmit(handleNovoTempDocumento)} isDisabled={!isFormComplete}>
              Adicionar
            </GenericButton>
          )}

          <GenericButton variant={'outline'} color={'#242731'} onClick={handleCloseNovoDucumentoModal}>
            Cancelar
          </GenericButton>
        </ModalFooter>
      </ModalContent>
    </ModalContainer>
  )
}
