import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { Button, Checkbox, Input } from '@chakra-ui/react'

import { editSubtarefa } from 'sigap/services/tarefas'
import { ISubtarefa } from 'sigap/types/tarefas'

import { InputStackContainer } from './styles'
import { SubtarefaInput } from './SubtarefaInput/SubtarefaInput'

interface IProps {
  subtarefas: ISubtarefa[]
  refreshSubtarefas: () => void
  tarefaId: number
}


export const InputStack: React.FC<IProps> = ({ subtarefas, refreshSubtarefas, tarefaId }) => {
  return (
    <InputStackContainer>
      {subtarefas?.map((subtarefa, index) => {
        return (
          <SubtarefaInput subtarefa={subtarefa} tarefaId={tarefaId} key={index} refreshSubtarefas={refreshSubtarefas} />
        )
      })}
      <SubtarefaInput tarefaId={tarefaId} refreshSubtarefas={refreshSubtarefas} />
    </InputStackContainer>
  )
}
