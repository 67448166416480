import { FORGOT_PASSWORD, LOGIN, RESET_PASSWORD, SIGN_UP, UPLOAD_PIC, USER, USERS, api } from 'sigap/utils/constants'

export interface IAuthUser {
  email: string
  password: string
}

export interface IAuthNewUser {
  email: string
  nome: string
  area_id: number
}

export interface INewUser {
  nome: string
  email: string
  area_id: number
  perfil_id: number
}

export interface IForgotPassword {
  email: string
}

export interface IResetPassword {
  password: string
  passwordVerify: string
}
export interface IPassword {
  password: string
}

export interface IUpdateUserInfo {
  nome: string
  email_notification: boolean
  is_active: boolean
}

export async function listUsers(): Promise<IUser[]> {
  const { data: response } = await api.get(`/${USERS}`)

  return response
}

export async function me(): Promise<IUser> {
  const { data: response } = await api.get(`/${USERS}/me`)

  return response
}

export async function login(user: IAuthUser): Promise<{ access_token: string; user_id: number }> {
  const { data: response } = await api.post(`/${USER}/${LOGIN}`, user)

  return response
}

export async function signUp(user: IAuthNewUser): Promise<{ access_token: string }> {
  const { data: response } = await api.post(`/${USER}/${SIGN_UP}`, user)

  return response
}

export async function newUser(user: INewUser): Promise<string> {
  const { data: response } = await api.post(`/${USER}/${SIGN_UP}`, user)

  return response
}

export async function forgotPassword(forgotPasswordEmail: IForgotPassword): Promise<{ access_token: string }> {
  const { data: response } = await api.post(`/${USER}/${FORGOT_PASSWORD}`, forgotPasswordEmail)

  return response
}

export async function resetPassword(forgotPasswordEmail: IPassword, token: string): Promise<string> {
  const { data: response } = await api.post(`/${USERS}/${RESET_PASSWORD}/${token}`, forgotPasswordEmail)

  return response
}

export async function updateUserInfo(updateUserInfo: IUpdateUserInfo, userId: number): Promise<any> {
  const response = await api.post(`/${USER}/update/${userId}`, updateUserInfo)

  return response
}

export async function uploadPic(userId: number, file: File): Promise<{ access_token: string }> {
  const data = new FormData()
  data.append('file', file, file.name)
  const { data: response } = await api.post(`/${USER}/${UPLOAD_PIC}/${userId}`, data)

  return response
}
