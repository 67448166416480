import React from 'react'
import DataTable, { TableColumn } from 'react-data-table-component'
import { BsPlusLg } from 'react-icons/bs'

import { CircularProgress } from '@chakra-ui/react'

import { GenericButton } from 'sigap/utils/generic/GenericButton/GenericButton'
import { NoDataComponent } from 'sigap/utils/generic/NoDataComponent/NoDataComponent'

interface IGenericTableProps<T> {
  columns: TableColumn<T>[]
  data: T[]
  title: string
  loading: boolean
  onRowClick: (row: T) => void
  onAddClick: () => void
  dataType: string
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const GenericAdminListTab = <T,>({
  columns,
  data,
  title,
  loading,
  onRowClick,
  onAddClick,
  dataType
}: IGenericTableProps<T>) => {
  return (
    <>
      <div className="button-row-container">
        <GenericButton onClick={onAddClick} colorScheme="green">
          Novo {dataType}
          <BsPlusLg />
        </GenericButton>
      </div>
      <div className="admin-body">
        <div className="table-title">
          <div className="table-title-header">{title}</div>
          <div className="table-container">
            {loading ? (
              <div className="loading-container">
                <CircularProgress isIndeterminate color="#001B37" />
              </div>
            ) : (
              <DataTable
                columns={columns}
                data={data}
                dense
                onRowClicked={onRowClick}
                pointerOnHover
                highlightOnHover
                striped
                noDataComponent={<NoDataComponent dataType={dataType} />}
              />
            )}
          </div>
        </div>
      </div>
    </>
  )
}
