import axios, { AxiosInstance } from 'axios'

import { storageService } from 'sigap/services/storageService'

import { ACCESS_TOKEN } from './localStorage'

interface IAPI {
  get: AxiosInstance['get']
  post: AxiosInstance['post']
  patch: AxiosInstance['patch']
  delete: AxiosInstance['delete']
  cancelPendingRequests: (reason?: string) => void
  updateAccessToken: (token: string) => void
}

export let cancelToken = axios.CancelToken.source()

export const getAPI = (): IAPI => {
  const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL
  })

  const accessToken = storageService.getItem(ACCESS_TOKEN)
  if (accessToken) {
    api.defaults.headers.common.Authorization = `Bearer ${accessToken}`
  }

  return {
    get(url, config) {
      return api.get(url, { ...(config ?? {}), cancelToken: cancelToken.token })
    },
    post(url, data, config) {
      return api.post(url, data, { ...(config ?? {}), cancelToken: cancelToken.token })
    },
    patch(url, data, config) {
      return api.patch(url, data, { ...(config ?? {}), cancelToken: cancelToken.token })
    },
    delete(url, config) {
      return api.delete(url, { ...(config ?? {}), cancelToken: cancelToken.token })
    },
    cancelPendingRequests(reason) {
      cancelToken.cancel(reason)
      cancelToken = axios.CancelToken.source()
    },
    updateAccessToken(token) {
      api.defaults.headers.common.Authorization = `Bearer ${token}`
    }
  }
}

export const api = getAPI()

export const PROJETOS = 'projetos'
export const PROJETO = 'projeto'
export const USER = 'user'
export const USERS = 'users'
export const LOGIN = 'login'
export const SIGN_UP = 'signup'
export const FORGOT_PASSWORD = 'forgot'
export const RESET_PASSWORD = 'resetpass'
export const UPLOAD_PIC = 'pepenapepena'
export const CONTRATOS = 'contratos'
export const CONTRATO = 'contrato'
export const PRODUTOS = 'produtos'
export const DEMANDAS = 'demandas'
export const EQUIPES = 'equipes'
export const SUBSECRETARIA = 'subsecretaria'
export const PUBLICO_ALVO = 'publicoalvo'
export const METAS = 'metas'
export const TAREFAS = 'tarefas'
export const DOCUMENTOS = 'documentos'
export const ODS = 'ods'
export const SEDEICS = 'sedeics'
export const INDICADORES = 'indicadores'
export const CLASSIFICACAO = 'classificacao'
export const CHART = 'chart'
