import { IProject } from 'sigap/types/projects'
import { PROJETOS, USER, api, cancelToken } from 'sigap/utils/constants'

export interface IAprovacaoObj {
  aprovado: boolean
  comentario: string
}

export async function getProject(projectId: number): Promise<IProject> {
  const { data: response } = await api.get(`/${PROJETOS}/${projectId}`)

  return response
}

export async function listProjects(): Promise<IProject[]> {
  const { data: response } = await api.get(`/${PROJETOS}`, { cancelToken: cancelToken.token })

  return response
}

export async function listProjectsByUser(userId: number): Promise<IProject[]> {
  const { data: response } = await api.get(`/${PROJETOS}/${USER}/${userId}`)

  return response
}

export async function insertProject(project: IProject): Promise<{ id_projeto: number }> {
  const { data: response } = await api.post(`/${PROJETOS}`, project)

  return response
}

export async function editProject(project: IProject): Promise<IProject> {
  const { data: response } = await api.patch(`/${PROJETOS}/${project?.id}`, project)

  return response
}

export async function insertCommentOnProject(comment: IComment, projectId: number): Promise<{ id_projeto: number }> {
  const { data: response } = await api.post(`/${PROJETOS}/${projectId}/comentario`, comment)

  return response
}

export async function getCommentsOnProject(projectId: number): Promise<IComment[]> {
  const { data: response } = await api.get(`/${PROJETOS}/${projectId}/comentario`)

  return response
}

export async function insertAprovacao(projectId: number, aprovacaoObj: IAprovacaoObj): Promise<void> {
  const { data: response } = await api.patch(`/${PROJETOS}/approve/${projectId}`, aprovacaoObj)

  return response
}

// /api/projetos/approve/{id_projeto}
