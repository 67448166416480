import React, { useCallback, useContext, useEffect, useState } from 'react'
import { DragDropContext, OnDragEndResponder } from 'react-beautiful-dnd'
import { BsFillCalendar2HeartFill } from 'react-icons/bs'
import { FaClipboardList } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'

import { Button } from '@chakra-ui/react'
import { useRequest } from 'ahooks'

import { AuthenticationContext } from 'sigap/context/AuthenticationContext'
import { editTarefa, getTarefasByUser, listTarefas } from 'sigap/services/tarefas'
import { ITarefa } from 'sigap/types/tarefas'
import { api } from 'sigap/utils/constants'
import { GenericNovaTarefaModal } from 'sigap/utils/generic/GenericNovaTarefaModal/GenericNovaTarefaModal'
import { LoadingScreen } from 'sigap/utils/generic/LoadingScreen/LoadingScreen'
import { SearchBar } from 'sigap/utils/generic/SearchBar/SearchBar'

import { Column } from './Column'
import { KanbanCalendar } from './KanbanCalendar/KanbanCalendar'
import { ContentContainer, FirstRowContainer, KanbanBoardContainer, KanbanCalendarContainer } from './styles'

import 'moment/locale/pt-br'
import { listContratos } from 'sigap/services/contracts'
import { listDemandas } from 'sigap/services/demandas'
import { listProjects } from 'sigap/services/projects'

export const KanbanBoard: React.FC = () => {
  const [tarefaMapByStatus, setTarefaMapByStatus] = useState<Map<number, ITarefa[]>>()
  const [showNovaTarefaModal, setShowNovaTarefaModal] = useState<boolean>(false)
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [tarefaModalData, setTarefaModalData] = useState<ITarefa>()
  const [page, setPage] = useState<string>('board')

  const authContext = useContext(AuthenticationContext)
  const statusNames = authContext?.currentUser?.status_tarefas

  const [columns, setColumns] = useState<IColumn[]>([
    {
      id: 1,
      name: statusNames?.todo ?? 'A fazer',
      color: '#B3C7E6'
    },
    {
      id: 2,
      name: statusNames?.progress ?? 'Fazendo',
      color: '#F5CBA7'
    },

    {
      id: 3,
      name: statusNames?.done ?? 'Terminada',
      color: '#C1E1C1'
    }
  ])

  const {
    data: tarefas,
    mutate,
    loading: tarefasLoading,
    refreshAsync: refreshTarefas,
    cancel: cancelListTarefas
  } = useRequest(listTarefas)

  const { data: projects } = useRequest(listProjects)
  const { data: contratos } = useRequest(listContratos)
  const { data: demandas } = useRequest(listDemandas)

  const navigate = useNavigate()

  const handleEditStatus = async (statusId: number, tarefaId: number): Promise<void> => {
    try {
      await editTarefa({ status_id: statusId + 1 }, tarefaId)
    } finally {
      console.log('a')
    }
  }

  const onDragEnd: OnDragEndResponder = useCallback((result) => {
    if (!result.destination || !result.source) return
    const { source, destination } = result
    if (source.droppableId !== destination.droppableId) {
      const tarefaId = Number(result.draggableId)
      // const sourceColumnId = columns.findIndex((column) => column.id.toString() === source.droppableId)
      const destinationColumnId = columns.findIndex((column) => column.id.toString() === destination.droppableId)
      handleEditStatus(destinationColumnId, tarefaId)
      mutate((old) =>
        old.filter((tarefa) => {
          if (tarefa.id_tarefa === tarefaId) {
            tarefa.status_id = destinationColumnId + 1
          }

          return tarefa
        })
      )
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (tarefas) {
      const tarefaMap = tarefas.reduce((acc, curr) => {
        curr.status_id = Math.min(Math.max(1, curr.status_id), 3)
        if (!acc.has(curr.status_id)) {
          acc.set(curr.status_id, [])
        }
        acc.get(curr.status_id).push(curr)

        return acc
      }, new Map<number, ITarefa[]>())
      setTarefaMapByStatus(tarefaMap)
    }
  }, [tarefas])

  if (tarefasLoading || !tarefaMapByStatus) {
    return <LoadingScreen />
  }

  return (
    <>
      {page === 'board' ? (
        <KanbanBoardContainer>
          <div className="dashboard-title">Minhas tarefas - Quadro</div>
          <FirstRowContainer>
            <SearchBar inputWidth={240} setSearchTerm={setSearchTerm} />
            <Button className="first-row-to-calendar" onClick={() => setPage('calendar')}>
              Calendário
              <BsFillCalendar2HeartFill />
            </Button>
          </FirstRowContainer>
          <DragDropContext onDragEnd={onDragEnd}>
            <ContentContainer>
              {columns?.map((column, index) => (
                <Column
                  columnData={column}
                  key={index}
                  tarefaMapByStatus={tarefaMapByStatus}
                  searchTerm={searchTerm}
                  setShowNovaTarefaModal={setShowNovaTarefaModal}
                  setTarefaModalData={setTarefaModalData}
                />
              ))}
            </ContentContainer>
          </DragDropContext>
        </KanbanBoardContainer>
      ) : (
        <KanbanCalendarContainer>
          <div className="dashboard-title">Minhas tarefas - Calendário</div>
          <FirstRowContainer className={page === 'calendar' ? 'end' : ''}>
            <Button className="first-row-to-calendar" onClick={() => setPage('board')}>
              Quadro
              <FaClipboardList />
            </Button>
          </FirstRowContainer>
          <KanbanCalendar
            tarefas={tarefas}
            setShowNovaTarefaModal={setShowNovaTarefaModal}
            setTarefaModalData={setTarefaModalData}
          />
        </KanbanCalendarContainer>
      )}
      <GenericNovaTarefaModal
        setShowNovaTarefaModal={setShowNovaTarefaModal}
        showNovaTarefaModal={showNovaTarefaModal}
        setTarefaModalData={setTarefaModalData}
        tarefaModalData={tarefaModalData}
        refreshTarefas={refreshTarefas}
        contratos={contratos}
        projects={projects}
        demandas={demandas}
      />
    </>
  )
}
