import React, { useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { Button, FormControl, Input, InputGroup, InputRightElement, useToast } from '@chakra-ui/react'
import { useRequest } from 'ahooks'

import { getAreaFromSubsecretaria, listSubsecretarias } from 'sigap/services/subsecretaria'
import { IAuthNewUser } from 'sigap/services/users'
import { USER_CREATION_TOAST_MESSAGE } from 'sigap/utils/constants/requestToastMessages'
import { GenericSelect } from 'sigap/utils/generic/GenericSelect/GenericSelect'

interface IFormData {
  nome: string
  email: string
  subsecretaria: Option<number>
  area: Option<number>
}

interface IProps {
  handleGoToDefaultAuth: () => void
  handleNewUser: (newUser: IAuthNewUser) => Promise<void>
}

export const NewUser: React.FC<IProps> = ({ handleGoToDefaultAuth, handleNewUser }) => {
  const { handleSubmit, control, watch } = useForm<IFormData>({
    mode: 'all'
  })

  const currentFormData = watch()

  const {
    data: subsecretarias,
    loading: subsecretariaLoading,
    error: subsecretariaError
  } = useRequest(listSubsecretarias)

  const {
    data: areas,
    runAsync: refreshArea,
    loading: areaLoading
  } = useRequest(getAreaFromSubsecretaria, {
    defaultParams: [currentFormData?.subsecretaria?.value?.toString()],
    ready: !!currentFormData?.subsecretaria?.value
  })

  const toast = useToast()

  const isFormComplete = useMemo(
    () =>
      currentFormData.nome?.length > 0 &&
      currentFormData.email?.length > 0 &&
      currentFormData.subsecretaria != null &&
      currentFormData.area != null,
    [currentFormData]
  )

  const handleClickSubmit = (formObject: IFormData): void => {
    const authUserObj: IAuthNewUser = {
      area_id: formObject.area.value,
      email: formObject.email,
      nome: formObject.nome
    }
    toast.promise<void, Error>(handleNewUser(authUserObj), USER_CREATION_TOAST_MESSAGE)
  }

  useEffect(() => {
    if (currentFormData.subsecretaria) {
      refreshArea(currentFormData.subsecretaria.value.toString())
      console.log(currentFormData)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFormData.subsecretaria])

  return (
    <>
      <div className="auth-header">Criar Conta</div>
      <FormControl className="form-control-container">
        <Controller
          name="nome"
          control={control}
          render={({ field }) => <Input variant="flushed" placeholder="Nome Completo" {...field} />}
        />
        <Controller
          name="email"
          control={control}
          render={({ field }) => <Input variant="flushed" placeholder="E-mail" type="email" {...field} />}
        />
        <Controller
          name="subsecretaria"
          control={control}
          render={({ field }) => (
            <GenericSelect
              placeholder="Subsecretaria"
              options={subsecretarias?.map((subsecretaria) => ({
                label: subsecretaria.nome,
                value: subsecretaria.id
              }))}
              {...field}
            />
          )}
        />
        <Controller
          name="area"
          control={control}
          render={({ field }) => (
            <GenericSelect
              placeholder="Área"
              isDisabled={!areas}
              options={areas?.map((area) => ({
                label: area.nome,
                value: area.id
              }))}
              {...field}
            />
          )}
        />
      </FormControl>
      <Button width={'100%'} colorScheme="green" onClick={handleSubmit(handleClickSubmit)} isDisabled={!isFormComplete}>
        Criar Conta
      </Button>
      <div className="bottom-link-container">
        <div className="go-back-login">
          Já possui uma conta?<span onClick={handleGoToDefaultAuth}>Login</span>
        </div>
      </div>
    </>
  )
}
